import Vue from 'vue'
import VueRouter from 'vue-router'
import request from '@/api/request'
import store from '@/store'

Vue.use(VueRouter)

let routes = [
  { //默认登录客户端
    path: '/',
    redirect: '/login',
  },
  { //客户端登录
    path:'/login',
    name:'login',
    component: ()=>import('views/login/index.vue'),
  },
  { //管理端登录
    path:'/login/admin',
    name:'loginAdmin',
    component: ()=>import('views/login/adminLogin.vue')
  },
  { //客户端首页
    path:'/clientSide/Home',
    name:'Home',
    meta:{showFooter: true},
    component: ()=>import('@/views/home/index.vue'),
    children:[
      {
        path:"/",
        redirect:"/clientSide/workBench"
      },
      { //工作台
        path:"/clientSide/workBench",
        name:"workBench",
        component:()=>import('@/views/Pages/clientSide/workBench.vue')
      },
    ]
  },
  { //管理端首页
    path:'/managementSide/Home',
    name:'Home',
    meta:{showFooter: true},
    component: ()=>import('views/home/index.vue'),
    children:[
      {
        path:"/",
        redirect:"/managementSide/workBench"
      },
      { //工作台
        path:"/managementSide/workBench",
        name:"workBench",
        component:()=>import('@/views/Pages/managementSide/workBench.vue')
      },
      { //WMS任务控制
        path:'/managementSide/WMSmissionContro',
        name:'WMSmissionContro',
        component:()=>import('@/views/Pages/managementSide/WMSmissionContro.vue')
      },
    ]
  },
  { //api文档
    path:'/apiDocument',
    name:'apiDocument',
    component: ()=>import('/src/views/document/apiDocument.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})

function getButtonPage(val, catalogueId, menuId){ //获取按钮页面
  if (val.length != 0) {
    let buttonPage = []
    val.map((subPage) => {
      buttonPage.push({
        path:subPage.menuPath,
        name:subPage.menuEnName,
        meta:{ showFooter: false, catalogueId:catalogueId, menuId:menuId, pageId:subPage.menuId  },
        component:()=>import(`../views/Pages/${window.localStorage.getItem("server")}/subPage/${subPage.menuRemark}.vue`)
      })
    })
    return buttonPage
  }else {
    return []
  }
}

let hasNecessaryRoute = true
router.beforeEach((to, from, next) => {
  // 检查是否访问登录页
  if (to.path !== "/login" && to.path !== "/login/" && to.path !== "/login/admin" && hasNecessaryRoute) {
    // 检查是否存在 token
    if (window.localStorage.getItem("token")) {
      // 发送异步请求获取用户角色和菜单
      request.post('/GetUserRole', {}, { loading: true }).then((res) => {
        const menuList = res.data[0].children;
        const server = window.localStorage.getItem("server");
        const menuChildren = [{ path: "/", redirect: `/${server}/workBench` }];
        // 动态生成菜单路由
        menuList.forEach((menu) => {
          if (menu.menuPath !== '') { //首页
            menuChildren.push({
              path: menu.menuPath,
              name: menu.menuEnName,
              component: () => import(`../views/Pages/${server}/${menu.menuEnName}.vue`)
            });
          } else {
            menu.children.forEach((catalogue) => {
              catalogue.children.forEach((page) => {
                menuChildren.push({
                  path: page.menuPath,
                  name: page.menuEnName,
                  meta: { showFooter: true, catalogueId:catalogue.menuId, menuId:menu.menuId, pageId:page.menuId  },
                  component: () => import(`../views/Pages/${server}/${page.menuEnName}.vue`),
                  children: getButtonPage(page.children, catalogue.menuId, menu.menuId)
                });
              });
            });
          }
        });

        // 添加动态生成的菜单路由
        router.addRoute({
          path: '/Home',
          name: 'Home',
          meta: { showFooter: true },
          component: () => import('@/views/home/index.vue'),
          children: menuChildren
        });

        // 添加 404 路由
        router.addRoute({
          path: '*',
          name: '404',
          component: () => import('@/views/error/errorpage.vue')
        });

        // 输出生成的菜单信息和目标路径
        // console.log(to);

        // 导航到目标路由
        hasNecessaryRoute = false  //防止重复重定向
        next({  //重定向到新地址并携带query
          path:to.path,
          query:to.query
        })
      }).catch((error) => {
        console.error("Async request failed:", error);
        // 处理路由创建失败的情况
        handleRouteCreationFailure(next);
      });
    } else {
      // 处理未登录的情况
      handleNotLoggedIn(next);
    }
  } else {
    // 已经在登录页，直接放行
    hasNecessaryRoute = true  //重置状态
    next();
  }
});

// 处理路由创建失败的情况
function handleRouteCreationFailure(next) {
  Vue.prototype.$alert(`路由创建失败，请尝试重新登录。`, '系统通知', {
    confirmButtonText: '确定',
    type: 'error',
  }).then(() => {
    store.dispatch("outLogin");
    if (window.localStorage.getItem("server") == "clientSide") {
      next("/login");
    } else {
      next("/login/admin");
    }
  });
}

// 处理未登录的情况
function handleNotLoggedIn(next) {
  Vue.prototype.$alert(`登录过期，请重新登录。`, '系统通知', {
    confirmButtonText: "确定",
    type: 'error',
  }).then(() => {
    store.dispatch("outLogin");
    if (window.localStorage.getItem("server") === "clientSide") {
      next("/login");
    } else {
      next("/login/admin");
    }
  });
}

//避免冗余导航到当前位置
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
//避免冗余导航到当前位置
//获取原型对象上的push函数
const originalReplace = VueRouter.prototype.replace
//修改原型对象中的push方法
VueRouter.prototype.replace = function push(location) {
  return originalReplace.call(this, location).catch(err => err)
}
router.onError((error) => {
  const jsPattern = /Loading chunk (\S)+ failed/g
  const cssPattern = /Loading CSS chunk (\S)+ failed/g
  const isChunkLoadFailed = error.message.match(jsPattern || cssPattern)
  const targetPath = router.history.pending.fullPath
  if (isChunkLoadFailed) {
    localStorage.setItem('targetPath', targetPath)
    window.location.reload()
  }
})

router.onReady(() => {
  const targetPath = localStorage.getItem('targetPath')
  const tryReload = localStorage.getItem('tryReload')
  if (targetPath) {
    localStorage.removeItem('targetPath')
    if (!tryReload) {
      router.replace(targetPath)
      localStorage.setItem('tryReload', true)
    } else {
      localStorage.removeItem('tryReload')
    }
  }
})

export default router