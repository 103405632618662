import instance from './interceptor'
import { Message,Loading } from 'element-ui';

/**
 * 核心函数，可通过它处理一切请求数据，并做横向扩展
 * @param {url} 请求地址
 * @param {params} 请求参数
 * @param {options} 请求配置，针对当前本次请求；
 * @param loading 是否显示loading
 */

function request(url,params,options={loading:false},headers,method){
  let loadingInstance;
  // 请求前loading
  if(options.loading)loadingInstance=Loading.service({
      target:'#main',
      spinner:'el-icon-loading',
      text:'加载中',
      background:"rgba(0, 0, 0, 0.6)"
  });
  return new Promise((resolve,reject)=>{
      let data = {}
      headers = headers || {"Content-Type": "application/json,charset=UTF-8","Access-Control-Allow-Origin":"*"}
      // get请求使用params字段
      if(method =='get') data = {params}
      // post请求使用data字段
      if(method =='post') {
        data = {data:params}
      }
      instance({
        url,
        method,
        headers,
        ...data
      }).then((res)=>{
        // 此处作用很大，可以扩展很多功能。
        // 比如对接多个后台，数据结构不一致，可做接口适配器
        // 也可对返回日期/金额/数字等统一做集中处理
        if (res.code == 200 || res.Code == 200) {
          resolve(res);
        }else {
          Message.error(res.msg)
          reject(res);
        }
      }).catch((error)=>{
        console.log(error)
        reject(error);
        Message.error(error.msg)
      }).finally((error)=>{
        reject(error);
        if(loadingInstance) loadingInstance.close();
      })
  })
}



//封装get请求
function get(url,params,options){
  return request(url,params,null,options,'get')
}
//封装POST请求
function post(url,params,options,header){
  return request(url,params,options,header,'post')
}
//封装Upload请求
function upload(url,params,options){
  let loadingInstance;
  console.log(params)
  // 请求前loading
  if(options.loading)loadingInstance=Loading.service({
    target:'#main',
    spinner:'el-icon-loading',
    text:'加载中',
    background:"rgba(0, 0, 0, 0.6)"
  });
  return new Promise((resolve,reject)=>{
    console.log(resolve,reject);
    instance({
      url,
      method:'post',
      headers:{
          "Content-type":'multipart/form-data'
      },
    }).then((res)=>{
      // 此处作用很大，可以扩展很多功能。
      // 比如对接多个后台，数据结构不一致，可做接口适配器
      // 也可对返回日期/金额/数字等统一做集中处理
      // if(res.return_codes == 0 || res.return_codes==-1){
      //   if(res.return_codes==-1) Message.warning(res.return_msg)
      //   resolve(res);
      // }else{
      //   Message.error(res.return_msg)
      //   reject(res);
      // }
      console.log(res);
    }).catch((error)=>{
      console.log(error)
      // Message.error(error.msg)
    }).finally(()=>{
      if(loadingInstance) loadingInstance.close();
    })
  })
}
export default {
  get,post,upload
}