<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  mounted(){
    document.addEventListener("visibilitychange",function(){  //当登录角色不一致强制重载页面
      let id = document.getElementById("userName")
      let server = document.getElementById("server")
      if (server.innerHTML != window.localStorage.getItem("server")) {
        this.$router.replace(`/${window.localStorage.getItem("server")}/workBench`)
      }else if (id.innerHTML != window.localStorage.getItem("UserName")) {
        location.reload();
      }
    })
  },
}
</script>


<style lang="scss">
#app{
  width: 100%;
  height: 100%;
}
*{
  padding: 0;
  margin: 0;
}
@import "./assets/style/base.scss"
</style>
