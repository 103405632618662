import Vue from 'vue'
import Vuex from 'vuex'
import login from "./modules/login"
import warehouse from './modules/warehouse'
import country from './modules/country'
import currency from './modules/currency'
import pieceFeeType from './modules/pieceFeeType'
import client from './modules/client'
import department from './modules/department'
import staffType from './modules/staffType.js'
import role from './modules/role.js'
import transitWarehouse from './modules/transitWarehouse.js'
import productAttribute from './modules/productAttribute.js'
import channelType from './modules/channelType'
import getRecipients from './modules/recipients'
import menuCollapse from './modules/menuCollapse'
import basketType from './modules/basketType.js'
import generalAttribute from './modules/generalAttribute.js'
import parentMenu from './modules/parentMenu.js'
import permissionWarehouse from './modules/permissionWarehouse.js'
import region from './modules/region.js'
import shop from './modules/shop.js'
import staff from './modules/staff.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    login,
    warehouse,
    country,
    currency,
    pieceFeeType,
    client,
    department,
    staffType,
    role,
    transitWarehouse,
    productAttribute,
    channelType,
    getRecipients,
    menuCollapse,
    basketType,
    generalAttribute,
    parentMenu,
    permissionWarehouse,
    region,
    shop,
    staff
  }
})
