let api = 'http://39.108.37.175:8086/'   //默认npm run build打包会使用这个地址
if (process.env.NODE_ENV === 'development') { //npm rin build 
  api="http://39.108.37.175:8086/"  //开发
} else if (process.env.VUE_APP_CURRENTMODE === 'test') {  //npm run build-test
  api="http://39.108.37.175:8086/"  //测试
} else if (process.env.VUE_APP_CURRENTMODE === 'prod') {  //npm run build-prod
  api="https://newwms.szestp.com:8089"  //深圳环境
} else if(process.env.VUE_APP_CURRENTMODE === 'prodPH'){  //npm run build-prodPH
  api="https://ph.szestp.com:8088"  //菲律宾环境
} else if(process.env.VUE_APP_CURRENTMODE === 'prodHK'){  //npm run build-prodHK
  api="https://ph.szestp.com:8088"  //香港环境
} 

export default api